export const formatDate = (options: any) =>
  new Intl.DateTimeFormat("en-Us", {
    ...options,
    timeZone: "America/Los_Angeles",
  });

/**
 * @function formatDateString
 *  @description accepts a date string and returns the format: 'Jan 1, 2024'
 *  @param {string} dateString Date String
 *  @param {string} locale E.g. en-US
 *  @param {object} options Date Time format options
 *  @param {object} options.month E.g. - "full" | "long" | "medium" | "short"
 *  @param {object} options.day E.g. - "narrow" | "short" | "long"
 *  @param {object} options.year E.g. - "full" | "long" | "medium" | "short"
 *  @param {object} options.weekday E.g. "narrow" | "short" | "long"
 *  @param {object} options.timeZone E.g. America/Los_Angeles, UTC
 *  @param {object} options.timeZoneName E.g. short
 */
export const formatDateString = (
  dateString: string,
  locale: string = "en-US",
  options?: Intl.DateTimeFormatOptions
) => {
  const formatOptions: Intl.DateTimeFormatOptions = {
    month: options?.month || "short",
    day: options?.day || "numeric",
    year: options?.year || "numeric",
    weekday: options?.weekday,
  };
  const isDateInvalid = Number.isNaN(new Date(dateString).getTime());

  if (isDateInvalid) {
    return "Invalid Date String";
  }
  return new Intl.DateTimeFormat(locale, formatOptions).format(
    new Date(dateString)
  );
};
