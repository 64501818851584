export const modalTheme = {
  modals: {
    // Modal components
    overlay: {
      zIndex: 200,
      position: "fixed",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      background: "rgba(0,0,0,0.7)",
      display: "flex",
      justifyContent: "center",
    },
    title: {
      padding: ["1rem", "2rem"],
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      minHeight: 16,
      borderBottomWidth: "1px",
      borderBottomStyle: "solid",
      borderBottomColor: "charcoal10",
    },
    content: {
      padding: ["1rem", "1.375rem"],
      flexGrow: 1,
      height: "auto",
      overflowY: "scroll",
    },
    footer: {
      px: ["1rem", "2rem"],
      py: ["1.5rem", "1.5rem"],
      height: "auto",
      display: "flex",
      alignItems: "center",
      borderTopWidth: "1px",
      borderTopStyle: "solid",
      borderTopColor: "charcoal10",
    },

    // Modal variants
    default: {
      backgroundColor: "background",
      borderRadius: "5px",
      borderWidth: "1px",
      borderStyle: "solid",
      borderColor: "charcoal70",
      boxShadow: "0px 0px 10px 0px rgba(0, 0, 0, 0.25)",
      display: "flex",
      flexDirection: "column",
      maxHeight: "80vh",
      minHeight: "16rem",
      minWidth: "16rem",
      width: "100%",
      maxWidth: "min(80vw, 60rem)",
      position: "absolute",
      top: ["25%", "10%"],
      zIndex: 210,
    },
  },
};
