import { Box } from "theme-ui";
import React, { useEffect } from "react";
import { useRouter } from "next/router";
import { useSession, signOut } from "next-auth/react";

// components
import Sidebar from "./Sidebar";
import TopBarLegacy from "./TopBarLegacy";

// hooks
import useSessionStorage from "../../hooks/useSessionStorage";

export interface Props {
  children: React.ReactNode;
}

export function DefaultLayout({ children }: Props) {
  const { data } = useSession();
  const { query, pathname } = useRouter();

  const [isSidebarExpanded, setIsSidebarExpanded] = useSessionStorage(
    "sidebarOpen",
    true
  );

  const isClientRoute = Boolean(query.client_id);
  const showLegacyLayout =
    pathname === "/" &&
    data?.groups?.includes("SuperAdmins") &&
    isClientRoute === false;

  if (showLegacyLayout) {
    return (
      <>
        <TopBarLegacy />
        {children}
      </>
    );
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        position: "relative",
      }}
    >
      <Sidebar
        isExpanded={isSidebarExpanded}
        toggleExpanded={() => {
          const isExpandedNew = !isSidebarExpanded;
          if (typeof window !== "undefined") {
            window.sessionStorage.setItem(
              "sidebarOpen",
              isExpandedNew.toString()
            );
          }
          setIsSidebarExpanded(isExpandedNew);
        }}
      />
      <Box
        style={{
          flex: 1,
          minHeight: "100vh",
          overflowY: "auto",
          paddingLeft: isSidebarExpanded ? "208px" : "56px",
        }}
      >
        {children}
      </Box>
    </Box>
  );
}
export default DefaultLayout;
