import React from "react";
import { Container, Text } from "theme-ui";
import { theme } from "../../../themes/base";

export function Footer() {
  return (
    <Container
      sx={{
        padding: "3rem",
        textAlign: "center",
        background: theme.colors.primary,
        color: theme.colors.white,
      }}
    >
      <Text>
        For more information, please contact&nbsp;
        <Text sx={{ ":hover": { textDecoration: "underline" } }}>
          <a
            href={`mailto:clientsupport@trueaccord.com`}
            aria-label="Click this link to email client support."
            style={{
              color: theme.colors.white,
              textDecoration: "none",
              whiteSpace: "nowrap",
              fontWeight: "bold",
            }}
          >
            clientsupport@trueaccord.com
          </a>
        </Text>
      </Text>
    </Container>
  );
}
export default Footer;
