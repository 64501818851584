/** @jsxImportSource theme-ui */
/**
 * Custom Next.js `App` component.
 *
 * @see https://nextjs.org/docs/advanced-features/custom-app
 * @see https://nextjs.org/docs/basic-features/built-in-css-support#adding-a-global-stylesheet
 * @see https://www.cdnperf.com
 * @see https://github.com/csstools/sanitize.css
 */

import "../components/Account/range.css";
import { Provider } from "mobx-react";
import { observer, useLocalObservable } from "mobx-react-lite";
import { SessionProvider, useSession, signOut } from "next-auth/react";
import { useRouter } from "next/router";
import { useEffect } from "react";
import { ToastContainer, Slide, toast } from "react-toastify";
import Smartlook from "smartlook-client";
import { SWRConfig } from "swr";
import { ThemeProvider } from "theme-ui";
import { v4 as uuidv4 } from "uuid";

import { DefaultLayout } from "../components/layouts/Default";
import { Footer } from "../components/layouts/Footer";
import { MetaHeader } from "../components/MetaHead";
import { SmartlookProjectKey, isProd } from "../src/helpers";
import { useStore } from "../store";
import { defaultTheme } from "../themes";
import { getPageTitle } from "../utils";

import "../public/css/sanitize.css";
import "../public/css/critical.css";
import "react-toastify/dist/ReactToastify.css";

const UnstableCheckForAccessToken = () => {
  const router = useRouter();
  const session = useSession();

  const handleRefreshSession = async () => {
    await signOut();
  };

  useEffect(() => {
    if (session.status === "authenticated") {
      if (Boolean(session.data.accessToken) === false) {
        handleRefreshSession()
          .then(async () => {
            await router.reload();
          })
          .catch(() => {});
      }
    }
  }, [session.status]);

  return null;
};

interface AppProps {
  Component: any;
  pageProps: any;
}

function RootApp({
  Component,
  pageProps: { session, ...pageProps },
}: AppProps) {
  const router = useRouter();
  const store = useStore(pageProps.initialState);
  pageProps.pageTitle = getPageTitle(router.asPath);

  const UI: any = useLocalObservable(() => ({
    displayMenu: false,
    handleUpdate: (key: keyof typeof UI, value: unknown) => {
      UI[key] = value;
    },
  }));

  try {
    if (isProd() && SmartlookProjectKey) {
      Smartlook.init(SmartlookProjectKey);
      Smartlook.identify(session?.user?.email || uuidv4(), {});
    }
  } catch (e) {
    console.error(e);
  }

  return (
    <SessionProvider session={session}>
      <UnstableCheckForAccessToken />
      <Provider store={store}>
        <SWRConfig
          value={{
            revalidateOnFocus: false,
          }}
        >
          <ThemeProvider theme={defaultTheme}>
            {/* HTML header component  */}
            <MetaHeader baseUrl={pageProps.baseUrl} />
            <DefaultLayout>
              <Component
                {...pageProps}
                handleUpdate={UI.handleUpdate}
                displayMenu={UI.displayMenu}
              />
            </DefaultLayout>
            {pageProps.isSuperAdmin && !pageProps.isClientIdSimulated ? (
              <Footer />
            ) : null}
            <ToastContainer
              position={toast.POSITION.TOP_RIGHT}
              autoClose={5000}
              hideProgressBar={false}
              newestOnTop={false}
              rtl={false}
              pauseOnFocusLoss={false}
              pauseOnHover
              transition={Slide}
              theme="colored"
            />
          </ThemeProvider>
        </SWRConfig>
      </Provider>
    </SessionProvider>
  );
}

export default observer(RootApp);
