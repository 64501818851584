import {
  applySnapshot,
  Instance,
  SnapshotIn,
  SnapshotOut,
} from "mobx-state-tree";
import { useMemo } from "react";
import { UserStore } from "./models";

let store: IStore | undefined;

const Store = UserStore;

export type IStore = Instance<typeof Store>;
export type IStoreSnapshotIn = SnapshotIn<typeof Store>;
export type IStoreSnapshotOut = SnapshotOut<typeof Store>;

export function initializeStore(snapshot = null) {
  const _store =
    store ??
    Store.create({
      clientId: "",
      clientName: "",
      expires: "",
      groups: [],
      user: null,
    });
  if (snapshot) applySnapshot(_store, snapshot);
  if (typeof window === "undefined") return _store;
  if (!store) store = _store;
  return store;
}

export function useStore(initialState: any) {
  // eslint-disable-next-line
  return useMemo(() => initializeStore(initialState), [initialState]);
}
