export const theme = {
  breakpoints: [
    "40em",
    "@media (min-width: 56em) and (orientation: landscape)",
    "64em",
    "96em",
  ],

  colors: {
    text: "#000",
    success: "#50BE4C",
    error: "#FF4136",
    alert: "#FF851B",
    informational: "#419AE6",
    background: "#FFF",
    darkCharcoal: "#262C31", //
    redBg: "rgba(255, 65, 54, 0.15)",
    lightCharcoal: "#F6F6F6",
    white: "#FFF",
    blue: "#0074D9",
    darkBlue: "#002F75",
    primary: "#003D99", // primary navy
    secondary: "#FF800A", // retain orange
    tertiary: "#009DDB", // trueml cyan
    charcoal10: "#EAEAEB",
    charcoal20: "#D4D5D6",
    charcoal35: "#BEBEBE",
    charcoal50: "#929597",
    charcoal60: "#7D8083",
    charcoal70: "#686C6F",
    charcoal75: "#676B6E",
    extendedBlue: "#419AE6",
    extendedGreen: "#008566",
    extendedOrange: "#FF9F4D",
    primaryLightCharcoal: "#F6F6F6",
    darkGreen: "#2E782C",
    greenBg: "rgba(38, 172, 33, 0.15)",
    secondaryGreen: "#00A881", // recover green
    darkOrange: "#DF6B05",
    recoverLightBlue: "#B2CEF5",
    retainViolet: "#9E5ABE",
    retainBrightBlue: "#24FBFF",
    lightBlue: "#EBFAFF", // trueml ext light blue
    retainOrange: "#FF800A",
    tableRowBg: "rgba(38, 172, 33, 0.15)",
    tableBorder: "#D4D5D6",
    retainExtRed: "#D53410", // cta ext red
    retainExtDarkRed: "#CF0B00", // cta ext dark red
  },

  fonts: {
    body: "inherit",
    heading: "inherit",
    cell: "inherit",
  },

  radii: [0, 4],

  styles: {
    root: {
      fontFamily: "body",
      color: "text",
      bg: "background",
      p: 0,
    },
    a: {
      color: "primary",
      fontWeight: "bold",
      textDecoration: "none",
      ":hover": {
        color: "secondary",
        textDecoration: "underline",
      },
    },
  },

  buttons: {
    primary: {
      cursor: "pointer",
    },
  },

  images: {
    logo: {
      width: "230px",
      height: "30px",
    },
    hamburger: {
      width: "20px",
      height: "20px",
    },
    avatar: {
      width: "32px",
      height: "32px",
    },
  },

  layout: {
    scrollMarginTop: "8.8rem",
  },

  cards: {
    primary: {
      padding: 2,
      borderRadius: 10,
      background: "white",
      border: "1px solid #d4d5d6", // charcoal20
      boxShadow: "0 0 10px 0 rgba(0, 0, 0, 0.1)",
    },
  },

  text: {
    cell: {
      fontSize: "clamp(0.5rem, 2vw, 0.8rem)",
      whiteSpace: "nowrap",
    },
    thead: {
      fontSize: "clamp(0.5rem, 2vw, 0.8rem)",
      whiteSpace: "nowrap",
    },
    sectionTitle: {
      fontWeight: "600",
      color: "white",
      fontSize: "20px",
    },
    widgetheader: {
      fontWeight: "600",
      color: "darkCharcoal",
      fontSize: "clamp(1.2rem, 2vw, 2rem)",
    },
    widgetbody: {
      fontWeight: "normal",
      color: "charcoal70",
      fontSize: "clamp(0.8rem, 2vw, 1rem)",
    },
    widgettitle: {
      fontWeight: "600",
      color: "darkCharcoal",
    },
    strong: {
      fontWeight: "600",
      color: "text",
      fontSize: "16px",
    },
    clientName: {
      fontWeight: "600",
      color: "text",
      lineHeight: "29px",
      fontSize: "24px",
    },
    bold: {
      fontWeight: "600",
      color: "text",
      fontSize: "16px",
    },
    small: {
      color: "text",
      fontSize: "14px",
    },
    tiny: {
      color: "text",
      fontSize: "12px",
    },
    link: {
      color: "text",
      fontSize: "12px",
    },
    "itty-bitty": {
      color: "text",
      fontSize: "10px",
    },
    noDataTitle: {
      fontWeight: "600",
      color: "charcoal35",
      fontSize: "36px",
    },
  },

  meta: {
    title: "Retain by TrueML - Client Dashboard",
    description: "Improve engagement. Collect more. Retain your customers.",
  },

  forms: {
    input: {
      borderColor: "primary",
      "&:focus": {
        borderColor: "charcoal50",
        boxShadow: "0 0 0 2px primary",
      },
    },
    checkbox: {
      color: "charcoal50",
      width: "32px",
      height: "32px",
    },
    textarea: {
      color: "darkCharcoal",
      borderColor: "charcoal50",
    },
  },

  table: {
    width: "100%",
    my: 4,
    backgroundColor: "white",
    borderCollapse: "collapse",
  },

  hr: {
    border: 0,
    borderBottom: "1px solid",
    borderColor: "tableBorder",
  },

  img: {
    maxWidth: "100%",
  },
};
