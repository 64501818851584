import { useSession } from "next-auth/react";
import { useRouter } from "next/router";
import { TRUE_ACCORD_CLIENT_ID } from "../../components/constants";

export const useClientId = (): string => {
  const session = useSession();
  const { query } = useRouter();

  const isSuperAdmin = session.data?.groups?.includes("SuperAdmins");
  const isTrueAccordClient = session.data?.clientId === TRUE_ACCORD_CLIENT_ID;
  const isTrueAccordMember = isSuperAdmin && isTrueAccordClient;

  // If the user is trueaccord member we then need to honor the clientId from the router otherwise the session always return true accord
  if (isTrueAccordMember) {
    if (query.clientId) {
      return query.clientId as string;
    }

    return query.client_id as string;
  }

  return session?.data?.clientId as string;
};
