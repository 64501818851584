// @ts-nocheck
import type { Theme } from "theme-ui";
import { theme } from "../base";
import { modalTheme } from "../modal";

const makeTheme = <T extends Theme>(t: T) => t;

export const defaultTheme = makeTheme({
  ...theme,
  ...modalTheme,
  buttons: {
    ...theme.buttons,
    primary: {
      padding: "8px 20px",
      fontSize: 14,
      cursor: "pointer",
      minHeight: 40,
      minWidth: 148,
      textTransform: "uppercase",
      transition: "all 0.25s ease-in-out",
      "&:hover": {
        bg: "darkBlue",
        transition: "bg 0.25s ease-in-out",
      },
      "&:disabled": {
        bg: "charcoal10",
        color: "charcoal50",
        cursor: "not-allowed",
        borderColor: "charcoal10",
      },
    },
    secondary: {
      cursor: "pointer",
      minHeight: 40,
      minWidth: 148,
      textTransform: "uppercase",
      padding: "8px 20px",
      fontSize: 14,
      color: "background",
      bg: "secondary",
      transition: "all 0.25s ease-in-out",
      "&:hover": {
        bg: "darkOrange",
        transition: "bg 0.25s ease-in-out",
      },
      "&:disabled": {
        padding: "8px 20px",
        fontSize: 14,
        bg: "charcoal10",
        color: "charcoal50",
        cursor: "not-allowed",
        borderColor: "charcoal10",
      },
    },
    outline: {
      color: "primary",
      cursor: "pointer",
      minHeight: 40,
      minWidth: 148,
      bg: "white",
      padding: "8px 20px",
      fontSize: 14,
      borderColor: "primary",
      borderWidth: "1px",
      borderStyle: "solid",
      textTransform: "uppercase",
      transition: "all 0.25s ease-in-out",
      "&:hover": {
        bg: "recoverLightBlue",
        transition: "all 0.25s ease-in-out",
      },
      "&:disabled": {
        bg: "charcoal10",
        color: "charcoal50",
        cursor: "not-allowed",
        borderColor: "charcoal10",
        padding: "8px 20px",
        fontSize: 14,
      },
    },
    accent: {
      color: "white",
      cursor: "pointer",
      minHeight: 40,
      minWidth: 148,
      padding: "8px 20px",
      fontSize: 14,
      bg: "retainExtRed",
      textTransform: "uppercase",
      transition: "all 0.25s ease-in-out",
      "&:hover": {
        bg: "retainExtDarkRed",
        transition: "all 0.25s ease-in-out",
      },
      "&:disabled": {
        bg: "charcoal10",
        color: "charcoal50",
        cursor: "not-allowed",
        padding: "8px 20px",
        fontSize: 14,
      },
    },
    success: {
      color: "white",
      cursor: "pointer",
      minHeight: 40,
      minWidth: 148,
      bg: "extendedGreen",
      padding: "8px 20px",
      fontSize: 14,
      textTransform: "uppercase",
      transition: "all 0.25s ease-in-out",
      "&:hover": {
        bg: "secondaryGreen",
        transition: "all 0.25s ease-in-out",
      },
      "&:disabled": {
        bg: "charcoal10",
        padding: "8px 20px",
        fontSize: 14,
        color: "charcoal50",
        cursor: "not-allowed",
        borderColor: "charcoal10",
      },
    },
  },
  text: {
    ...theme.text,
    caps: {
      textTransform: "uppercase",
      letterSpacing: ".2em",
    },
    heading: {
      fontFamily: "heading",
      fontWeight: "heading",
      lineHeight: "heading",
    },
    display: {
      variant: "text.heading",
      fontSize: [6, 7, 8],
      fontWeight: "display",
    },
  },
  banner: {
    display: "flex",
    width: "100%",
    maxWidth: "600px",
    alignItems: "flex-start",
    borderRadius: "4px",
    border: "2px solid",
    borderColor: "extendedBlue",
    background: "#FFF",
    warning: {
      borderColor: "extendedOrange",
    },
    success: {
      borderColor: "extendedGreen",
    },
    alert: {
      display: "flex",
      width: "50px",
      padding: "18px 7px",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      flexShrink: "0",
      alignSelf: "stretch",
      background: "extendedBlue",
      borderRadius: "1px 0px 0px 1px",
      color: "white",
      warning: {
        background: "extendedOrange",
      },
      success: {
        background: "extendedGreen",
      },
    },
    title: {
      marginBOttom: "14px",
      fontWeight: 600,
    },
    content: {
      display: "flex",
      padding: "15px",
      flexDirection: "column",
      alignItems: "flex-start",
      gap: "10px",
      flex: "1 0 0",
      fontSize: 14,
      color: "darkCharcoal",
    },
  },
});
