import { Instance, types } from "mobx-state-tree";

export const User = types.model();

export const UserStore = types
  .model({
    __typename: types.optional(types.literal("User"), "User"),
    clientId: types.string,
    clientName: types.string,
    expires: types.string,
    groups: types.array(types.string),
    user: types.frozen({
      name: types.maybe(types.string),
      email: types.maybe(types.string),
      image: types.maybeNull(types.string),
    }),
  })
  .actions((self) => ({
    updateUser(newState: { [key: string]: any }) {
      self.clientId = String(newState.clientId);
      self.clientName = String(newState.clientName);
      self.expires = String(newState.expires);
      self.groups = newState.groups;
      self.user = Object.assign(
        {},
        {
          name: newState.user.name,
          email: newState.user.email,
          image: newState.user.image,
        }
      );
    },
  }));

export interface UserStoreType extends Instance<typeof UserStore> {}

export default UserStore.create({
  clientId: "",
  clientName: "",
  expires: "",
  groups: [],
  user: null,
});
